// Core
import React, { useEffect, useState, useRef, useCallback } from "react"
import debounce from "lodash.debounce"

// InView detector
import { InView } from "react-intersection-observer"

import { isMobile } from "react-device-detect"

// Components
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "./../split-big-heading/split-big-heading"

// Styles
import styles from "./our-companies.module.scss"

//SVG

const OurCompaniesNew = props => {
  const { data } = props

  const title = data.sectionTitle,
    companies = data.companies

  return (
    <ScrollSection>
      <section className={styles.companies}>
        <InView triggerOnce={true} threshold={0.2}>
          {({ inView, ref, entry }) => (
            <div
              className="cols flex flex--center"
              ref={ref}
              inview={inView.toString()}
            >
              <div className={`col m12 ${styles.heading}`} data-scroll>
                <SplitHeading classString={`h2 ${styles.h2}`}>
                  {title}
                </SplitHeading>
              </div>
              <div className={`col m12 ${styles.filtercontainer}`} data-scroll>
                {companies.map(({ title, description, companies }, index) => (
                  <div key={index} className={`${styles.filterwrap}`}>
                    <div className={`${styles.filteritemhead}`}>
                      <span className={`${styles.filteritemheadcontent}`}>
                        <h2>{title}</h2>
                        <p>{description}</p>
                      </span>
                    </div>
                    <div className={`${styles.filtercards}`}>
                      <ul className={styles.filterlist}>
                        {companies.map((item, index) => (
                          <li
                            key={index}
                            className={`${styles.filteritem}`}
                            data-category={item.cat}
                          >
                            {item.companyWebsite ? (
                              <a
                                target="_blank"
                                href={item.companyWebsite}
                                aria-label={`${item.name}'s website`}
                                className={styles.filteriteminner}
                              >
                                <div className={styles.imagewrapper}>
                                  <img
                                    src={item.companyImage.fluid.src}
                                    alt=""
                                  />
                                </div>
                                <span className={styles.filteritemtext}>
                                  {item.tag && (
                                    <span
                                      className={`${styles.filteritemtext} ${styles.tagcontaining}`}
                                    >
                                      <span className={styles.tag}>
                                        {item.tag}
                                      </span>
                                    </span>
                                  )}
                                  <span className={styles.copy}>
                                    {item.name}
                                  </span>
                                  <span className={styles.cattitle}>
                                    {item.subtitle}
                                  </span>
                                </span>
                              </a>
                            ) : (
                              <div className={styles.filteriteminner}>
                                <div className={styles.imagewrapper}>
                                  <img
                                    src={item.companyImage.fluid.src}
                                    alt=""
                                  />
                                </div>
                                <span
                                  className={`${styles.filteritemtext} ${styles.tagcontaining}`}
                                >
                                  {item.tag && (
                                    <span className={styles.tag}>
                                      {item.tag}
                                    </span>
                                  )}

                                  <span className={styles.copy}>
                                    {item.name}
                                  </span>
                                  <span className={styles.cattitle}>
                                    {item.subtitle}
                                  </span>
                                </span>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </InView>
      </section>
    </ScrollSection>
  )
}

export default OurCompaniesNew
