import React from "react"
import FocusCard from "../focus-card/focus-card"

// Component
import ScrollSection from "../locomotiveScroll/scrollSection"

//SVG
import Circle from "./../../../static/images/svg/focusellipse.inline.svg"

// Styles
import styles from "./index.module.scss"

const OurFocus = props => {
  const content = props?.content,
    heading = props?.heading

  return (
    <ScrollSection>
      <section className={styles.ourFocus}>
        <Circle />
        <div className={`${styles.container}`}>
          {content?.map(({ title, copy, image }, index) =>
            index === 0 ? (
              <FocusCard
                title={title}
                copy={copy}
                image={image}
                heading={heading}
              />
            ) : index % 2 === 1 ? (
              <FocusCard
                title={title}
                copy={copy}
                image={image}
                contentAlign={"right"}
              />
            ) : (
              <FocusCard title={title} copy={copy} image={image} />
            )
          )}
          {/* <FocusCard contentAlign={"left"} /> */}
        </div>
      </section>
    </ScrollSection>
  )
}

export default OurFocus
