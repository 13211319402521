import React, { useContext, useEffect } from "react"
import { ContextDispatch } from "./../../context/Context"

//Components
import Accordion from "../../components/accordion-and-image/accordion-and-image"
import Footer from "../../components/footer/footer"
import Hero from "../../components/large-hero/large-hero-2"
import NextPage from "../../components/next-page/next-page"
import Text from "../../components/text/text-focus"
import SEO from "../../components/seo/seo"
import OurFocus from "../../components/our-focus/our-focus"
import OurCompaniesNew from "../../components/our-companies-new/our-companies"

const PageTemplate3 = props => {
  const page = props,
    templateData = page?.data?.contentfulTemplate3

  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_DARK" })
    }
    header()
  }, [storeDispatch])

  const heroData = {
    heroImage: templateData?.heroImage,
    heroTitle: templateData?.heroTitle,
    heroText: templateData?.heroText,
  }

  let textblock, accordionblock, companiesblock, nextpageblock, ourFocus

  if (templateData.accordionImageItems) {
    console.log(templateData, "acc_data")
    accordionblock = <Accordion content={templateData} />
  }
  if (templateData.text) {
    textblock = <Text content={templateData.text} />
  }

  // nextpageblock = (
  //   <NextPage title={templateData.nextPageLinkText} url={``} contact={true} />
  // )

  if (templateData.nextPage) {
    nextpageblock = (
      <NextPage
        heading={templateData.nextPage.heading}
        title={templateData.nextPage.linkTitle}
        url={`/` + templateData.nextPage.page.slug}
      />
    )
  } else {
    nextpageblock = (
      <NextPage
        heading={`Ready To Talk?`}
        title={`Get In Touch`}
        url={``}
        contact={true}
      />
    )
  }

  if (templateData.ourCompanies) {
    companiesblock = <OurCompaniesNew data={templateData.ourCompanies} />
  }

  if (templateData.accordionImageItems) {
    console.log(templateData, "acc_data")
    ourFocus = (
      <OurFocus
        content={templateData.accordionImageItems}
        heading={templateData.pageTitle}
      />
    )
  }

  return (
    <React.Fragment>
      <SEO
        title={templateData.seoData.seoTitle}
        image={templateData.seoData.seoImage}
        description={templateData.seoData.seoDescription}
      />
      <Hero heroData={heroData} circle={true} />
      {textblock}
      {/* {accordionblock} */}
      {ourFocus}
      {companiesblock}
      {nextpageblock}
      <Footer />
    </React.Fragment>
  )
}

export default PageTemplate3

export const pageQuery = graphql`
  query template3NewPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTemplate3(slug: { eq: $slug }) {
      ...template3NewQuery
    }
  }
`
