// Core
import React, { useContext } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { ContextDispatch } from "./../../context/Context"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import JsonText from "../_jsonText"
import ScrollSection from "../locomotiveScroll/scrollSection"

//SVG
import Circle from "./../../../static/images/svg/text-quarter.inline.svg"

// Styles
import styles from "./text.module.scss"
import buttonStyles from "../button/button.module.scss"

import ButtonCircle from "./../../../static/images/svg/button-circle.inline.svg"
import Arrow from "./../../../static/images/svg/arrow.inline.svg"

const Text = props => {
  const { content } = props,
    { link } = props,
    { circle } = props,
    { center } = props,
    { theme } = props

  let buttonTheme, buttonUrl, buttonProps, buttonTarget, internal

  if (link?.link) {
    if (link.link.buttonDestination) {
      buttonTheme = "light"
      buttonProps = link.link
      buttonUrl = link.link.buttonDestination
      buttonTarget = link.link.buttonTarget
    } else {
      internal = true
      buttonTheme = "light"
      buttonProps = link.link
    }
  } else if (link) {
    buttonTheme = "light"
    buttonProps = link

    buttonUrl = link.buttonDestination
    buttonTarget = link.buttonTarget
  }
  // const { buttonText } = props

  // let buttonTheme

  // if (props.buttonTheme) {
  //   buttonTheme = props.buttonTheme
  // }

  const storeDispatch = useContext(ContextDispatch)

  const exitAnim = (exit, node) => {
    storeDispatch({ type: "SWIPE_IN" })
  }

  const entryAnim = (entry, node) => {
    storeDispatch({ type: "SWIPE_OUT" })
  }

  return (
    <React.Fragment>
      <ScrollSection>
        <InView triggerOnce={true} threshold={0.2}>
          {({ inView, ref, entry }) => (
            <section
              className={`${styles.textFocus} ${styles.text} ${
                circle === "top" ? styles.circleTop : ``
              } ${center ? styles.center : ``} ${theme ? styles.theme : ``}`}
              ref={ref}
              inview={inView.toString()}
            >
              <div className="container">
                <div className="cols flex flex--center">
                  <div
                    className={`${center ? `col m12 t5 d4` : `col m12 t5`}`}
                    data-scroll
                    data-scroll-speed={0.6}
                    data-scroll-delay={0.6}
                  >
                    <JsonText
                      content={content}
                      split={true}
                      classString={`copy copy--m ${styles.copy}`}
                    />
                    <>
                      {link && !internal ? (
                        <a
                          href={buttonUrl}
                          target={buttonTarget ? `_blank` : ``}
                          rel={buttonTarget ? `noreferrer` : ``}
                          className={`button ${
                            buttonTheme ? `button--${buttonTheme}` : ``
                          }  ${buttonStyles.link}`}
                        >
                          <span>
                            <span className={buttonStyles.circle}>
                              <ButtonCircle />
                              <span className={buttonStyles.arrowWrap}>
                                <span className={buttonStyles.arrow}>
                                  <Arrow />
                                </span>
                                <span className={buttonStyles.arrow}>
                                  <Arrow />
                                </span>
                              </span>
                            </span>
                            <span className={buttonStyles.buttonText}>
                              <span>See current job openings</span>
                            </span>
                          </span>
                        </a>
                      ) : internal ? (
                        <TransitionLink
                          to={`/` + buttonProps.slug}
                          className={`button ${
                            buttonTheme ? `button--${buttonTheme}` : ``
                          }  ${buttonStyles.link}`}
                          exit={{
                            trigger: ({ exit, node }) => exitAnim(exit, node),
                            length: 0.6,
                            zIndex: 2,
                          }}
                          entry={{
                            trigger: ({ entry, node }) =>
                              entryAnim(entry, node),
                            delay: 1,
                            length: 1,
                            zIndex: 1,
                          }}
                        >
                          <span>
                            <span className={buttonStyles.circle}>
                              <ButtonCircle />
                              <span className={buttonStyles.arrowWrap}>
                                <span className={buttonStyles.arrow}>
                                  <Arrow />
                                </span>
                                <span className={buttonStyles.arrow}>
                                  <Arrow />
                                </span>
                              </span>
                            </span>
                            <span className={buttonStyles.buttonText}>
                              <span>{link.linkTitle}</span>
                            </span>
                          </span>
                        </TransitionLink>
                      ) : (
                        ``
                      )}
                    </>
                  </div>
                </div>
              </div>
              {/* <Circle /> */}
            </section>
          )}
        </InView>
      </ScrollSection>
    </React.Fragment>
  )
}

export default Text
