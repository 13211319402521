import React, { useEffect, useState } from "react"

// Component
import SplitHeading from "../split-big-heading/split-big-heading"
import SplitText from "../split-text/split-text"
import JsonText from "../_jsonText"

// Styles
import styles from "./index.module.scss"

// InView detector
import { InView } from "react-intersection-observer"
import FocusButton from "../focus-button/focus-button"

const FocusAreasCard = props => {
  const title = props?.title,
    copy = props?.copy?.copy,
    image = props?.image,
    heading = props?.heading,
    contentAlign = props?.contentAlign

  const [threshold, setThreshold] = useState()

  useEffect(() => {
    if(typeof window != "undefined"){

  
    if (window.innerWidth >= 1240) {
      setThreshold(0.5)
    } else {
      setThreshold(0.2)
    }
  }
  }, [typeof window != "undefined" &&  window.innerWidth])

  let textContent

  const text =
    "We believe that the future of Artificial Intelligence and automation hinges on the accessibility of large-scale, balanced, and generalizable data. And so we are focused on building diverse data sources to train innovative models."

  if (copy) {
    textContent = copy?.raw ? (
      <JsonText content={copy} split={true} />
    ) : (
      <SplitText classString={`copy ${styles.copy}`}>{copy}</SplitText>
    )
  }

  return (
    <InView triggerOnce={true} threshold={threshold}>
      {({ inView, ref, entry }) => (
        <div
          className={`${styles.focusCard} ${
            contentAlign === "right" ? styles.textRight : ""
          }`}
          ref={ref}
          inview={inView.toString()}
        >
          <div
            className={styles.contentContainer}
            data-scroll
            data-scroll-speed={1}
          >
            {heading && (
              <SplitHeading classString={`${styles.heading}`} h1>
                {heading}
              </SplitHeading>
            )}
            <div className={styles.detail}>
              {title && (
                <SplitText classString={`${styles.subheading}`}>
                  {title}
                </SplitText>
              )}
              {textContent && (
                <div className={styles.typography}>{textContent}</div>
              )}
              <div>
                <FocusButton
                  buttonTheme={"light"}
                  buttonProps={{
                    buttonText: "Read More",
                    typeOfLink: {
                      // buttonTarget: "New Tab",
                      buttonDestination: "/healthcare-ai",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <div className={styles.imageInner}>
              {/* <img src={StaticImg} /> */}
              {image && <img src={image.fluid.src} alt={image.description} />}
            </div>
          </div>
        </div>
      )}
    </InView>
  )
}

export default FocusAreasCard
